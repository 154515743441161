.sacarHoverBoton:hover{
background-color: transparent!important;
}



.preventCambioColor:hover{
    color: #004e56!important;
}


.textoSecunBlog{
    font-size: 24px!important;
    font-weight: 600!important;
}

.textoHighBlog{
    color:#2e7d32!important;
    font-weight: 600!important;
}

.textoHighBlogLink{
    color:gray!important;
    font-weight: 600!important;
}

.modBlogSnap1{
    max-width: 100%!important;
    font-size: 32px!important;
  }

  
  @media screen and  (min-width: 600.01px) {
    .modBlogSnap1{
      max-width: 1000px!important;
      font-size: 42px!important;
    }
  }

  .modBlogSnap3{
    width: 100%!important;
   }
 
   @media screen and  (min-width: 900.01px) {
     .modBlogSnap3{
      width: 70%!important;
     }
   }

   
   .modBlogSnap4{
    margin-bottom: 32px!important;
   }

   @media screen and  (min-width: 600.01px) {
    .modBlogSnap4{
     margin-bottom: 48px!important;
    }
  }

   @media screen and  (min-width: 833.61px) {
    .modBlogSnap4{
     height: 500px!important;
    }
  }

  .modBlogSnap5{
    font-size:16px!important;
   }

   @media screen and  (min-width: 600.01px) {
    .modBlogSnap5{
      font-size:18px!important;
    }
  }

  .modBlogSnap6{
    font-size:32px!important;
   }

   @media screen and  (min-width: 600.01px) {
    .modBlogSnap6{
      font-size:42px!important;
    }
  }

  .margenTopoBlog{
    margin-top: 12px!important;
  }

  @media screen and  (min-width: 600.01px) {
    .margenTopoBlog{
      margin-top: 34px!important;
    }
  }

  .modBlogSnap2{
    margin-top: 32px!important;
  }

  @media screen and  (min-width: 600.01px) {
    .modBlogSnap2{
      margin-top: 48px!important;
    }
  }

  .ocultoONoBlog{
    display: none!important;
   }
 
   @media screen and  (min-width: 900.01px) {
     .ocultoONoBlog{
      display: contents!important;
     }
   }
 